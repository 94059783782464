@font-face {
  font-family: "Prompt";
  font-weight: normal;
  font-style: normal;
  src: local("Prompt"),
    url(./assets/Prompt/Prompt-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Prompt";
  font-weight: 100;
  font-style: normal;
  src: local("Prompt"), url(./assets/Prompt/Prompt-Thin.ttf) format("truetype");
}
@font-face {
  font-family: "Prompt";
  font-weight: 200;
  font-style: normal;
  src: local("Prompt"), url(./assets/Prompt/Prompt-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Prompt";
  font-weight: 400;
  font-style: normal;
  src: local("Prompt"),
    url(./assets/Prompt/Prompt-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Prompt";
  font-weight: 500;
  font-style: normal;
  src: local("Prompt"),
    url(./assets/Prompt/Prompt-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Prompt";
  font-weight: 600;
  font-style: normal;
  src: local("Prompt"),
    url(./assets/Prompt/Prompt-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Operator-Mono";
  src: local("Operator-Mono"),
    url(./assets/FontsFree-Net-OperatorMono-Medium.ttf) format("truetype");
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.ml-auto {
  margin-left: auto;
}

::-webkit-scrollbar {
  height: 2px;
  width: 2px;
}

::-webkit-scrollbar-track {
  width: 2px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  width: 2px;
  opacity: 0.8;
  background: #f1f1f1;
}
