.collabEditorParamChecker {
  opacity: 0;
  display: flex;
  height: 100vh;
  width: 100vw;
  color: white;
  &.visible {
    opacity: 1;
    transition: opacity 5s;
  }
}
